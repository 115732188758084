import { MAINTAIN_PERMISSION, LABEL_VERIFICATION_OPERATORS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Operators", "has-delete": true, "actions-width": 120, "create-button-text": "Add Operator" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("div", { staticClass: "d-flex justify-content-end w-100" }, [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Search by Operator Name")];
    }, proxy: true }]) }, [_c("keyword-filter", { attrs: { "field": "OperatorName", "placeholder": "Search by Operator Name", "allow-clear": "" } })], 1)], 1)];
  }, proxy: true }, _vm.can(_vm.permissions.maintain) ? { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var toggleActive = ref.toggleActive;
    var del = ref.del;
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "size": "small", "type": "link", "icon": "edit", "disabled": record.isInactive }, on: { "click": function($event) {
      return edit(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "size": "small", "type": "link", "icon": "delete", "disabled": record.isInactive }, on: { "click": function($event) {
      return del(record);
    } } })], 1)];
  } } : null], null, true) }, [_c("text-field", { key: "OperatorId", attrs: { "title": "Operator ID", "data-index": "operatorId", "sorter": true } }), _c("text-field", { key: "OperatorName", attrs: { "title": "Operator Name", "data-index": "operatorName", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var Operators_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "Operators",
  inject: ["crud", "getFacility", "apiUrl", "can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Operators = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "label-verification.operators", "api-url": _vm.apiUrl, "create-route": "/label-verification/operators/create", "edit-route": "/label-verification/operators/:id", "page": _vm.page } }, [_c("operators")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { Operators },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "label-verification",
          title: "Label Verification",
          path: ""
        },
        {
          key: "master-files",
          title: "Master Files",
          path: ""
        },
        {
          key: "operators",
          title: "Operators",
          path: ""
        }
      ],
      page: LABEL_VERIFICATION_OPERATORS
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
